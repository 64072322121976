import * as React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    // [BLOCKS.EMBEDDED_ASSET]: (node) => {
    //   return (
    //     <>
    //       <h2>Embedded Asset</h2>
    //       <pre>
    //         <code>{JSON.stringify(node, null, 2)}</code>
    //       </pre>
    //     </>
    //   );
    // },
    "embedded-asset-block": (node) => {
      const { gatsbyImageData } = node.data.target;
      const image = getImage(gatsbyImageData);

      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return (
        <GatsbyImage
          className="imageMargin"
          placeholder="blurred"
          image={image}
        />
      );
    },
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

// markup
const AboutPage = ({ data }) => {
  const { body } = data.contentfulAboutPage;

  console.log(body);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About • System of Systems</title>
      </Helmet>
      <div className="_2across gridCentered topOfPage">
        <div className="aboutBody">{body && renderRichText(body, options)}</div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulAboutPage {
      body {
        raw
      }
    }
  }
`;

export default AboutPage;
